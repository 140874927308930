import { Modal } from '../../Shared/Ant'
import React, { FC, ReactElement, useState } from 'react'
import { GenericProductFormProps, Product } from '../types'
import { ProductFormBody } from '../ProductFormBody/ProductFormBody'
import { apiCall } from '../../../api/apiCall'
import { Requests } from '../../../api/requests'
import { camelCaseToSnakeCase } from '../../../utils/camelCaseToSnakeCase'
import { Provider } from 'react-redux'
import { store } from '../../../store'
import '../../Shared/Ant/App.less'

export const EditGenericProduct: FC<GenericProductFormProps> = ({
  allowStateSpecificPricing,
  companyId,
  product,
  returnChoices,
  preferredReturnChoice
}): ReactElement => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const saveProduct = (
    values: Product,
    showDocumentChecklist: boolean,
    hasSpecialInstructions: boolean,
    showStateSpecificPricing: boolean
  ) => {
    const params = {
      id: product.id,
      product: values,
      documentChecklistCheckbox: showDocumentChecklist,
      clearSpecialInstructions: !hasSpecialInstructions,
      hasStateSpecificPricing: showStateSpecificPricing
    }

    apiCall
      .put(Requests.product(params).path, {
        ...camelCaseToSnakeCase(params)
      })
      .then(() => {
        setIsModalVisible(false)
        window.location.reload()
      })
  }

  return (
    <Provider store={store}>
      <a onClick={showModal}>{product.productName}</a>

      <Modal
        title={'Edit Product'}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="product-form"
        width={700}
      >
        <ProductFormBody
          allowStateSpecificPricing={allowStateSpecificPricing}
          companyId={companyId}
          product={product}
          submitBtnText={'Save Product'}
          returnChoices={returnChoices}
          preferredReturnChoice={preferredReturnChoice}
          fromOrderForm={false}
          saveProduct={saveProduct}
        />
      </Modal>
    </Provider>
  )
}
