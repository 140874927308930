import React, { FC, ReactElement } from 'react'
import { Icons } from '../../Shared/Ant'
import { Menu, Checkbox, Dropdown } from 'antd'
import { NotificationSettingsParams } from './types'
import { Requests } from '../../../api/requests'
import { get, set } from 'lodash'
import { updateResourceSync } from '../../../actions/updateResourceSync'

export const NotificationSettings: FC<NotificationSettingsParams> = ({
  notificationSettings,
  notificationSettingKeys,
  confirmBrowserExit
}): ReactElement => {
  const request = Requests.notificationSettings({
    id: notificationSettings.id,
    companyId: notificationSettings.companyId
  })

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedValues = {}
    notificationSettingKeys.forEach((notificationSettingKey) => {
      set(updatedValues, notificationSettingKey, e.target.checked)
    })

    updateResourceSync(set({}, request.type, set({}, request.id, updatedValues)))
    confirmBrowserExit.enable()
  }

  const checked = () => {
    let notChecked: boolean = false

    notificationSettingKeys.forEach((notificationSettingKey) => {
      if (!get(notificationSettings, notificationSettingKey, false)) {
        notChecked = true
      }
    })

    return !notChecked
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <Checkbox
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
          value={checked()}
          className="setting-options"
          checked={checked()}
        >
          Schedulers and admins
        </Checkbox>
      </Menu.Item>
    </Menu>
  )

  return (
    <div className="settings-block-row-options">
      <Dropdown overlay={menu} trigger={['click']}>
        <a onClick={(e) => e.preventDefault()}>
          <Icons.CaretDown className="icon" />
          <span className="selected-values">{(checked() && 'Schedulers and admins') || 'None selected'}</span>
        </a>
      </Dropdown>
    </div>
  )
}
