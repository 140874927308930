import React, { FC, ReactElement, useState } from 'react'
import { Row, Col, Button } from 'antd'
import { SettingsFooterParams } from './types'
import { updateResource } from '../../../actions/updateResource'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { Requests } from '../../../api/requests'
import { set } from 'lodash'
import { updateResourceSync } from '../../../actions/updateResourceSync'

export const SettingsFooter: FC<SettingsFooterParams> = ({
  emailSettings,
  notificationSettings,
  confirmBrowserExit
}): ReactElement => {
  const [isLoading, setIsLoading] = useState(false)
  const emailSettingsRequest = Requests.emailSettings({ id: emailSettings.id, companyId: emailSettings.companyId })
  const notificationSettingsRequest = Requests.notificationSettings({
    id: notificationSettings.id,
    companyId: notificationSettings.companyId
  })
  const dispatch = useAppDispatch()

  const handleSave = (save: boolean) => {
    setIsLoading(true)

    if (save) {
      setIsLoading(true)
      dispatch<any>(
        updateResource({
          path: emailSettingsRequest.path,
          type: emailSettingsRequest.type,
          resource: emailSettings,
          params: { email_settings: emailSettings }
        })
      ).then(() => {
        dispatch<any>(
          updateResource({
            path: notificationSettingsRequest.path,
            type: notificationSettingsRequest.type,
            resource: notificationSettings,
            params: {
              notification_settings: notificationSettings
            }
          })
        ).then(() => {
          setIsLoading(false)
          confirmBrowserExit.disable()
        })
      })
    } else {
      dispatch<any>(
        updateResource({
          path: emailSettingsRequest.path,
          type: emailSettingsRequest.type,
          resource: emailSettings,
          params: { reset: true }
        })
      ).then(() => {
        setIsLoading(false)
        const initialValues = {}
        Object.keys(notificationSettings).forEach((notificationSettingKey) => {
          if (notificationSettingKey !== 'id' && notificationSettingKey !== 'companyId')
            set(initialValues, notificationSettingKey, true)
        })
        updateResourceSync(
          set({}, notificationSettingsRequest.type, set({}, notificationSettingsRequest.id, initialValues))
        )
      })
    }
  }

  return (
    <Row className="settings-footer">
      <Col>
        <Button loading={isLoading} type="primary" onClick={() => handleSave(true)} size={'large'}>
          Save changes
        </Button>
        <a className={'reset-link'} onClick={() => handleSave(false)}>
          Reset to default settings
        </a>
      </Col>
    </Row>
  )
}
