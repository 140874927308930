export const Requests = {
  appConfig: () => ({
    id: -1,
    path: `app_configuration`,
    type: 'appConfig'
  }),
  company: <T extends { id: number }>(params: T) => ({
    id: params.id,
    path: `company/${params.id}`,
    type: 'company'
  }),
  order: <T extends { id: string | number }>(params: T) => ({
    path: `orders/${params.id}`,
    type: `orders`
  }),
  orders: <T>(params: T) => ({
    path: `orders?${params}`,
    type: `orders`
  }),
  notification: <T extends { id: number }>(params: T) => ({
    id: params.id,
    path: `notifications/${params.id}`,
    type: 'notifications'
  }),
  orderComment: <T extends { id: number; orderId: number }>(params: T) => ({
    id: params.id,
    path: `orders/${params.orderId}/comments/${params.id}`,
    type: 'orderComment'
  }),
  notifications: <T extends { owner: string; status: string; sort: string; page: number }>(params: T) => ({
    path: `notifications`,
    type: 'notifications',
    params
  }),
  notificationSettings: <T extends { id: number; companyId: number }>(params: T) => ({
    id: params.id,
    path: `company/${params.companyId}/notification_settings/${params.id}`,
    type: `notificationSettings`
  }),
  unreadNotificationsCount: <T extends { owner: string }>(params: T) => ({
    id: -1,
    path: `notifications/unread_count?owner=${params.owner}`,
    type: 'unreadNotificationsCount',
    params
  }),
  notificationUpdateBulk: () => ({
    path: `notifications/update_bulk`,
    type: `notificationsUpdateBulk`
  }),
  dashboardCounts: <T extends { owner: string; teamId: string; filter: string }>(params: T) => ({
    id: -1,
    path: `orders/dashboard_counts?owner=${params.owner}&company_team_id=${params.teamId}&filter=${params.filter}`,
    type: 'dashboardCounts',
    params
  }),
  emailSettings: <T extends { id: number; companyId: number }>(params: T) => ({
    id: params.id,
    path: `company/${params.companyId}/email_settings/${params.id}`,
    type: `emailSettings`
  }),
  featurePolicies: <T extends { companyId: number }>(params: T) => ({
    id: params.companyId,
    path: `company/${params.companyId}/feature_policies`,
    type: `featurePolicies`
  }),
  newOrderImport: () => ({
    path: `pending_orders/new_order_import`
  }),
  clients: <T>(params: T) => ({
    path: `clients`,
    type: `clients`,
    params
  }),
  products: (params: any) => ({
    path: `products`,
    params
  }),
  product: <T extends { id: number }>(params: T) => ({
    id: params.id,
    path: `products/${params.id}`,
    params
  }),
  clientProducts: <T extends { clientId: number }>(params: T) => ({
    path: `clients/${params.clientId}/products`,
    type: `products`,
    params
  }),
  clientTeamMembers: <T extends { clientId: number; email: string }>(params: T) => ({
    path: `clients/${params.clientId}/team_members?email=${params.email}`,
    type: `clientTeamMembers`,
    params
  }),
  users: <T extends { email: string }>(params: T) => ({
    path: `users?email=${params.email}`,
    type: `users`,
    params
  }),
  pendingOrderSigners: <T extends { pendingOrderId: string }>(params: T) => ({
    path: `pending_orders/${params.pendingOrderId}/signers`,
    type: `pendingOrderSigners`,
    params
  }),
  attachmentPreview: <T extends { orderId: number; attachmentId: number | null }>(params: T) => ({
    path: `/orders/${params.orderId}/attachment_previews/${params.attachmentId}`
  }),
  postClosingReviews: <T extends { orderId: number }>(params: T) => ({
    path: `orders/${params.orderId}/post_closing_reviews`,
    type: `postClosingReviews`,
    params
  }),
  isLoading: <T extends { status: string }>(request: T): boolean => {
    return !request || request.status !== 'SUCCEEDED'
  },
  hasLoaded: <T extends { status: string }>(request: T): boolean => {
    return request && request.status === 'SUCCEEDED'
  },
  adminNotaries: <T>(params: T) => ({
    path: `admin/notaries?${params}`,
    type: `adminNotaries`
  }),
  notary: <T extends { id: number }>(params: T) => ({
    path: `notaries/${params.id}`,
    type: `notary`
  }),
  matchParticipantEmails: (email: string) => ({
    path: `observer_emails?email=${email}`,
    type: `observerEmails`
  })
}
