import { snakeCaseToCamelCase } from '../utils/snakeCaseToCamelCase'
import { get, snakeCase } from 'lodash'
import { apiCall } from './apiCall'

const fetchResources = async <T extends { path: string; type: string }>(config: T) => {
  const { path, type } = config

  try {
    const response = await apiCall.get(path, { params: get(config, 'params', {}) })
    const data = get(response, ['data', snakeCase(type)])
    const resources = Array.isArray(data)
      ? data.map((resource) => snakeCaseToCamelCase(resource))
      : snakeCaseToCamelCase(data)
    const meta = snakeCaseToCamelCase(get(response, ['data', 'meta'], {}))

    return { resources, meta }
  } catch (e) {
    return e.response.data
  }
}

export const ReactQuery = { fetchResources }
