import React, { FC, ReactElement } from 'react'
import { Row, Col } from 'antd'
import { SettingRowParams } from './types'
import { SettingOptions } from './SettingOptions'
import { hideEmailSetting } from './utils'
import { NotificationSettings } from './NotificationSettings'
import { get } from 'lodash'

export const SettingRow: FC<SettingRowParams> = ({
  id,
  companyId,
  setting,
  featurePolicies,
  notificationSettings,
  confirmBrowserExit
}): ReactElement => {
  if (hideEmailSetting(featurePolicies, setting.dependencies)) {
    return <></>
  }

  const settingNotAvailable = (text: string) => <p className="setting-not-available">{text}</p>

  return (
    <div className="settings-block-row">
      <Row>
        <Col span={10}>
          <p className="settings-block-row-title">{setting.title}</p>
          <p className="settings-block-row-description">{setting.description}</p>
        </Col>
        <Col span={8}>
          {(!get(setting, 'notificationSettingKeys') && settingNotAvailable('Notification not available')) || (
            <NotificationSettings
              notificationSettings={notificationSettings}
              notificationSettingKeys={setting.notificationSettingKeys as string[]}
              confirmBrowserExit={confirmBrowserExit}
            />
          )}
        </Col>
        <Col span={6}>
          {(setting.key === '' && settingNotAvailable('Email not available')) || (
            <SettingOptions id={id} companyId={companyId} setting={setting} confirmBrowserExit={confirmBrowserExit} />
          )}
        </Col>
      </Row>
    </div>
  )
}
