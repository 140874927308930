import React, { FC, ReactElement } from 'react'
import { ProductOrderFormParams, Product } from '../types'
import { ProductFormBody } from '../ProductFormBody/ProductFormBody'
import { apiCall } from '../../../api/apiCall'
import { Requests } from '../../../api/requests'
import { camelCaseToSnakeCase } from '../../../utils/camelCaseToSnakeCase'
import { Provider } from 'react-redux'
import { store } from '../../../store'
import '../../Shared/Ant/App.less'

export const AddProductFromOrder: FC<ProductOrderFormParams> = ({
  allowStateSpecificPricing,
  companyId,
  product,
  preferredReturnChoice
}): ReactElement => {
  const saveProduct = (
    values: Product,
    showDocumentChecklist: boolean,
    hasSpecialInstructions: boolean,
    showStateSpecificPricing: boolean,
    visibleForAllClients: boolean
  ) => {
    // hacky way to pass the client id along until the order form is in react, will remove in MNS-2565
    const clientElement = document.getElementsByClassName('product-form-client-id')[0] as HTMLInputElement
    const clientId = clientElement.getAttribute('value')

    if (clientId) {
      values.clientId = parseInt(clientId)
    }

    const params = {
      product: values,
      documentChecklistCheckbox: showDocumentChecklist,
      clearSpecialInstructions: !hasSpecialInstructions,
      hasStateSpecificPricing: showStateSpecificPricing,
      visibleForAllClients
    }

    apiCall
      .post(Requests.products(params).path, {
        ...camelCaseToSnakeCase(params)
      })
      .then((resp) => {
        // hide modal in hacky non bootstrap way b/c jquery won't work here
        const product = resp.data
        const modal = document.getElementById('new-product-modal') as HTMLInputElement
        const backdrops = document.getElementsByClassName('modal-backdrop') as HTMLCollection

        for (let i = 0; i < backdrops.length; i++) {
          const backdrop = backdrops[i] as HTMLInputElement
          backdrop.style.display = 'none'
        }

        modal.style.display = 'none'
        modal.classList.remove('show')

        // add new product to select dropdown
        const productSelect = document.getElementById('product-select') as HTMLSelectElement

        if (productSelect) {
          const opt = document.createElement('option')
          opt.value = product.id
          opt.innerText = product.product_name
          opt.setAttribute('data-fee', product.vendor_fee || 0)
          opt.setAttribute('data-hybrid', product.hybrid_signing)
          productSelect[0] = opt
        }

        // have to re-enable scrolling on the page because select2 disables it
        document.body.style.overflow = 'visible'
      })
  }

  return (
    <Provider store={store}>
      <ProductFormBody
        allowStateSpecificPricing={allowStateSpecificPricing}
        companyId={companyId}
        product={product}
        submitBtnText={'Create Product'}
        returnChoices={[]}
        preferredReturnChoice={preferredReturnChoice}
        fromOrderForm={true}
        saveProduct={saveProduct}
      />

      <input className="product-form-client-id hidden" value="" />
    </Provider>
  )
}
